import React, {useState} from 'react';
import { view } from '@risingstack/react-easy-state';
import { Col, Row, Button,  Typography, Modal } from 'antd';
import { PlusCircleFilled } from '@ant-design/icons';
import StaffList from './StaffList.jsx';
import StaffForm from './StaffForm.jsx';
import staffStore from './staffStore.js'
import moment from 'moment';

export default view(()=> {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };

  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    const handleCreateStaff = async (values) => {
        const rawValues = values;
        const formatDate = rawValues.date.format('YYYY-MM-DD');
        const formatStartTime = rawValues.startTime.format('HH:mm:ss');
        const processedStartTime = moment(`${formatDate} ${formatStartTime}`, 'YYYY-MM-DD HH:mm:ss', true).format();
        rawValues.startTime = processedStartTime;
        staffStore.createStaff(rawValues).then(() => {
            handleCancel()
        })
        return true
    }

    return (
        <>
            <Row className="page-row" aling="top">
                <Col className="page-column home-main-col" span={14} offset={3}>
                    <Typography className="page-title">
                        Mes staffs
                    </Typography>
                    {/* <Typography className="page-sub-title">
                        Staff à lancer
                    </Typography>
                    <Typography className="page-content-placeholder">
                        Vous n’avez pas de staff à venir.
                    </Typography>
                    <Typography className="page-sub-title">
                        Staff à préparer
                    </Typography> */}
                    <StaffList />
                </Col>
                <Col className="page-column maxElemHeightCol" span={5}>
                    <Typography className="page-title">
                        Créer un staff
                    </Typography>
                    <Button id="createMeetingBtn" onClick={showModal}>
                        <PlusCircleFilled />
                        Ajouter un staff
                    </Button>
                </Col>
            </Row>
            <Modal 
                className="neo-common-modal" 
                title="Ajouter un Staff" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={null}
                >
                <StaffForm handleSubmit={handleCreateStaff} defaults={null} />
            </Modal>
        </>
    )
})
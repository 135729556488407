import React, {useEffect, useState} from 'react';
import { view } from '@risingstack/react-easy-state';
import { Steps, Form, Button } from 'antd';

import caseStore from './caseStore.js';
import FirstStep from './CaseFormFirstStep.jsx';
import SecondStep from './CaseFormSecondStep.jsx';

const { Step } = Steps;

export default view((props)=> {
    const { defaults, handleSubmission, meetingId } = props;
    const [current, setCurrent] = useState(0);
    const [firstStepValues, setFirstStepValues] = useState("");
    const [secondStepValues, setSecondStepValues] = useState("");
    const [form] = Form.useForm();
    const next = () => {
        form.validateFields()
        .then(values => {
            setCurrent(current + 1);
            setFirstStepValues(values);
        })
        .catch(errorInfo => { 
            console.log("errorInfo", errorInfo);
        });
    };
    
    const prev = () => {
        setCurrent(current - 1);
        const getValues = form.getFieldsValue();
        setSecondStepValues(getValues);
    };
    
    const mergeSubmit = () => {
        form.validateFields()
        .then(values => {
            setSecondStepValues(values);
            const allForm = Object.assign(firstStepValues, values);
            if(!handleSubmission) {
                caseStore.createCase(allForm, meetingId);
            } else {
                const caseId = caseStore.subjectCase._id;
                handleSubmission(allForm, meetingId, caseId)
                .then(() => {
                    form.resetFields();
                })
            }
        })
        .then(()=>{
            if(caseStore.status === "succeeded"){
                form.resetFields();
                setCurrent(current - 1);
            }
        })
        .catch(errorInfo => { 
            caseStore.error= errorInfo.message;
        });

    }
    const steps = [
        {
            title: 'First',
            content: (
                <FirstStep defaults={defaults} form={form} />
            ),
        },
        {
            title: 'Second',
            content: (
                <SecondStep defaults={defaults} form={form} />
            ),
        },

    ];
    return (
        <>
            <Form 
                onFinish={(values)=>{console.log(values)}}
                form={form}
                initialValues={defaults}
            >
                <Steps id="create-case-steps" current={current}>
                    {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <div className="steps-content">{steps[current].content}</div>
            </Form>
                <div className="steps-action">
                    {current < steps.length - 1 && (
                    <Button className="full-profile-form-button edit-profile-button" onClick={() => next()}>
                        Suivant
                    </Button>
                    )}
                    {current > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                        Précédent
                    </Button>
                    )}
                    {current === steps.length - 1 && (
                    <Button className="full-profile-form-button edit-profile-button" onClick={mergeSubmit}>
                        Valider
                    </Button>
                    )}
                </div>
        </>
    )
})
import React, {useRef, useEffect} from 'react';
import { view } from '@risingstack/react-easy-state';
import { Col, Row, Form, Input, Select, Typography, Button, Slider } from 'antd';
import { PlusCircleFilled, MinusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export default view((props)=> {
    const init = useRef(null)
    const { defaults, form } = props;

    useEffect(()=> {
        const listVal = form.getFieldValue("diagnosisList")
        if(!listVal) {
            init.current.click();
        }
    }, [form])

    return (
        <>
            <Row align="top" gutter={40}>
                <Col span={12}>
                    <Typography.Title className="formSectionLabel">
                        Problématique du cas
                    </Typography.Title>
                    <Form.Item
                        name="diagnosisComment"
                        rules={[
                        {
                            required: true,
                            message: 'Quelle question souhaite-vous soumettre à vos confrères?',
                        },
                        ]}
                        initialValue={defaults ? defaults.diagnosisComment : null}
                    >
                        <Input.TextArea autoSize placeholder="Commentaire"></Input.TextArea>
                    </Form.Item>
                    <Form.List name="diagnosisList">
                        {(fields, { add, remove }) => (
                        <>

                            {fields.map(field => (
                            <div  key={field.key} align="baseline">
                                <Typography.Title className="formSectionLabel">
                                    N° 1 - Possibilité{field.key+1}
                                </Typography.Title>
                                <Form.Item
                                {...field}
                                name={[field.name, 'probableDiagnosis']}
                                fieldKey={[field.fieldKey, 'probableDiagnosis']}
                                rules={[{ required: true, message: 'Possibilité(s) envisagée(s)' }]}
                                >
                                    <Input.TextArea autoSize placeholder="Possibilité(s) envisagée(s)"></Input.TextArea>
                                </Form.Item>
                                <Typography.Title className="radioLabel">
                                    Quel est votre niveau de certitude pour cette possibilité?
                                </Typography.Title>
                                <span className="vote-slider-wrapper case-form-vote-wrapper">
                                    <span className="left-label">pas sûr du tout</span>
                                    <Form.Item
                                    {...field}
                                    name={[field.name, 'possibility']}
                                    fieldKey={[field.fieldKey, 'possibility']}
                                    rules={[{ required: true, message: 'Renseignez vôtre niveau de certitude' }]}
                                    >
                                        <Slider min={0} max={100} tooltipVisible={false} />
                                    </Form.Item>
                                    <span className="right-label">très sûr</span>
                                </span>
                                <Button className="dashedFullWidthButton" onClick={() => remove(field.name)} block icon={<MinusCircleOutlined />}>
                                    Supprimer possibilité
                                </Button>
                            </div>
                            ))
                            }


                            <Form.Item>
                            <Button ref={init} id="addLinkToCaseBtn" onClick={() => add()} block icon={<PlusCircleFilled />}>
                                Ajouter une possibilité
                            </Button>
                            </Form.Item>
                        </>
                        )}
                    </Form.List>
                </Col>
                <Col span={12}>
                    <Typography.Title className="formSectionLabel">
                        Spécialistes demandés
                    </Typography.Title>
                    <Form.Item
                        name="speciality"
                        rules={[
                        {
                            required: true,
                            message: 'Renseignez la spécialité susceptible de vous aider sur ce cas',
                        },
                        ]}
                    >
                        <Select placeholder="Spécialité">
                            <Option value='Allergie et immunologie'>Allergie et immunologie</Option>
                            <Option value='Anesthésiologie'>Anesthésiologie</Option>
                            <Option value='Dermatologie'>Dermatologie</Option>
                            <Option value='Radiologie diagnostique'>Radiologie diagnostique</Option>
                            <Option value="Médecine d'urgence">Médecine d'urgence</Option>
                            <Option value='Médecine familiale'>Médecine familiale</Option>
                            <Option value='Médecine interne'>Médecine interne</Option>
                            <Option value='Génétique médicale'>Génétique médicale</Option>
                            <Option value='Neurologie'>Neurologie</Option>
                            <Option value='Médecine nucléaire'>Médecine nucléaire</Option>
                            <Option value='Obstétrique et de gynécologie'>Obstétrique et de gynécologie</Option>
                            <Option value='Ophtalmologie'>Ophtalmologie</Option>
                            <Option value='Pathologie'>Pathologie</Option>
                            <Option value='Pédiatrie'>Pédiatrie</Option>
                            <Option value="Médecine physique et réadaptation">Médecine physique et réadaptation</Option>
                            <Option value='Médecine préventive'>Médecine préventive</Option>
                            <Option value='Psychiatrie'>Psychiatrie</Option>
                            <Option value="Radio-oncologie">Radio-oncologie</Option>
                            <Option value='Opération'>Opération</Option>
                            <Option value='Urologie'>Urologie</Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
})
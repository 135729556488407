import React, { useEffect, useState } from 'react';

import { view } from '@risingstack/react-easy-state';
import { Button, Table, Modal, Badge } from 'antd';
import { PlusCircleFilled, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import CaseForm from './CaseForm.jsx';
import CertaintyRate from './CertaintyRate.jsx';
import staffStore from '../staff/staffStore.js'
import caseStore from './caseStore.js'

import moment from 'moment';
import { useHistory } from 'react-router-dom';

export default view((props)=> {
    const history = useHistory();

    const { meetingId, caseList } = props;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const theMeeting = staffStore.fetchedStaffList.find(item => item._id === meetingId);
    const sortedCases = theMeeting.caseList.sort((a, b) => moment(a.createdAt).isBefore(b.createdAt))
    useEffect(()=> {
        if(caseStore.status === "succeeded") {
            handleCancel()
        }
    }, [caseList.length])
    const showModal = () => {
        setIsModalVisible(true);
    };
    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const columns = [
        { title: "N° du patient", dataIndex: 'name', key: 'caseName', render: (text, record) => <span title={record.id}>{record.caseId}</span> },
        // { title: "Date d'ajout", dataIndex: 'createdAt', key: 'caseDate',
        //     render: (text, record) => {
        //         const date = moment(record.createdAt).format("DD/MM/YYYY");
        //         return date;
        //     } 
        // },
        { title: "Spécialité", dataIndex: 'speciality', key: 'speciality'},
        { title: "Niveau d'incertitude", dataIndex: 'certainty', key: 'caseCertainty', render: (text, record) => (
            <CertaintyRate filledQuantity={record.certainty} diameter={14} />
        )},
        { title: 'Statut', dataIndex: '', key: 'caseStatus', render: (text, record) => {
            let content;
            record.caseStatus 
                ? content = <CheckCircleFilled className="status-icon icon-check" />
                : content = <ExclamationCircleFilled className="status-icon icon-exclamation"/>
            return content;
        } },

        { title: '', dataIndex: '', key: 'caseStatus', render: (text, record) => {
            let content = <span className="vote-badge-wrapper"> <Badge className="vote-badge" title={`${record.diagnosisList[0].vote.length} vote(s)`} count={record.diagnosisList[0].vote.length} showZero /> </span>;
            
            return content;
        } },
    ];

    return (
        <>
            <div className="caseTable">
                <Table 
                    columns={columns} 
                    dataSource={sortedCases}
                    pagination={{
                        "hideOnSinglePage": true,
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {history.push(`/staff/${meetingId}/case/${record._id}`)}, // click row
                        };
                    }}
                />
            </div>
            <Button onClick={showModal}
                id="createCaseBtn">
                <PlusCircleFilled />
                    Ajouter un cas
            </Button>
            <Modal 
                className="neo-common-modal" 
                title="Ajouter un cas" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={null}
                >
                <CaseForm meetingId={meetingId} defaults={null} />
            </Modal>
        </>
    )

})
import React, { useState } from 'react';
import { view } from '@risingstack/react-easy-state';
import { Col, Row, Typography, Button, Form } from 'antd';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import staffStore from './staffStore.js';
import StaffForm from './StaffForm.jsx';

export default view((props)=> {
    const { staff, isEditProp } = props;
    const history = useHistory();

    const handleEdit = async (values) => {
        let processedData = values;
        processedData['meetingId'] = staff._id;
        await staffStore.editStaff(values);
        history.push("/home")
        return true
    };

    const detailView = (<><Row align="top" gutter={40}>
                            <Col span={12}>
                                <Typography.Title className="staff-detail-section-label">
                                    Quoi ?
                                </Typography.Title>
                                <Typography.Title className="staff-detail-field-label">
                                    Nom du Staff
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {staff.name}
                                </Typography.Paragraph>

                                <Typography.Title className="staff-detail-field-label">
                                    Contenu
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {staff.description}
                                </Typography.Paragraph>

                                <Typography.Title className="staff-detail-section-label">
                                    Quand ?
                                </Typography.Title>
                                <Typography.Title className="staff-detail-field-label">
                                    Date du Staff
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {moment(staff.startTime).format("DD/MM/YYYY")}
                                </Typography.Paragraph>

                                <Typography.Title className="staff-detail-field-label">
                                    Début
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {moment(staff.startTime).format("HH:mm")}
                                </Typography.Paragraph>

                                <Typography.Title className="staff-detail-field-label">
                                    Durée
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {staff.duration}
                                </Typography.Paragraph>
                            </Col>

                            <Col span={12}>
                                <Typography.Title className="staff-detail-section-label">
                                    Où ?
                                </Typography.Title>
                                <Typography.Title className="staff-detail-field-label">
                                    Hôpital
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {staff.hospital}
                                </Typography.Paragraph>

                                <Typography.Title className="staff-detail-field-label">
                                    Service
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {staff.service}
                                </Typography.Paragraph>

                                <Typography.Title className="staff-detail-field-label">
                                    Salle
                                </Typography.Title>
                                <Typography.Paragraph className="staff-detail-field-value">
                                    {staff.room}
                                </Typography.Paragraph>
                            </Col>
                        </Row>
                        </>
                        )

    return (
        <>
            {
                isEditProp
                    ? <StaffForm defaults={staff} handleSubmit={handleEdit} />
                    : detailView
            }
        </>
    )
})
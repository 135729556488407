import React, { useEffect } from 'react';
import { view } from '@risingstack/react-easy-state';
import { useParams } from "react-router-dom";
import { Row, Col } from 'antd';


import caseStore from './caseStore.js';
import staffStore from '../staff/staffStore.js';
import PatientDetail from './PatientDetail.jsx';
import VoteList from './VoteList.jsx';

export default view((props)=> {
    let { meeting_id, case_id } = useParams();
    useEffect(()=> {
        const foundMeeting = staffStore.fetchedStaffList.find((item) => item._id === meeting_id);
        const foundCase = foundMeeting.caseList.find((item) => item._id === case_id);
        caseStore.subjectCase = foundCase;
    }, [case_id, meeting_id])

    let content;
    if(!caseStore.subjectCase) {
        content = "Loading";
    } else {
        content = (
            <>
                <Row gutter={[32, 32]}>
                    <Col xs={{ span: 11 }} lg={{ span: 11 }}>
                        <PatientDetail />
                    </Col>

                    <Col className="white-corner-col background-detail-column" xs={{ span: 11 }} lg={{ span: 11 }}>
                        <VoteList />
                    </Col>
                </Row>
            </>
        );
    }

    return (
        <>
            {content}
        </>
    )
})
import { view } from '@risingstack/react-easy-state';
import { Link } from 'react-router-dom'
import { Row, Col, Typography } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import caseStore from './caseStore.js';
import { UserIcon } from '../../assets/icons/custom/user';
import CertaintyRate from './CertaintyRate.jsx';

export default view(()=> {
    const patientData = caseStore.subjectCase;
    let links;

    if(patientData.clinicalExamLinks.length>0) {
        links = patientData.clinicalExamLinks.map(item => (
            <Col className="link-column" xs={{ span: 15, offset: 7 }} lg={{ span: 15, offset: 7 }}>
                <div className="link-wrapper">
                    <Typography.Text className="link-title">{item.description}</Typography.Text>
                    <a target="_blank" rel="noreferrer" className="link-button" href={item.link}>Lien Orbis</a>
                </div>
            </Col>    
        ))
    }

    return (
        <>
            <Row gutter={20}>
                <Col className="white-corner-col" xs={{ span: 7 }} lg={{ span: 7 }}>
                    <Link className="toHomeLink" to={"/home"}>
                        <LeftOutlined />Les autres cas
                    </Link>
                    <br />
                    <span className="detail-row">
                        <span className="detail-user-icon-wrapper"><UserIcon className="detail-user-icon" /></span><Typography.Text className="case-number">{patientData.caseId}</Typography.Text>
                    </span>
                    <span className="detail-row">
                        <Typography.Text className="inline-label">GENRE</Typography.Text> <Typography.Text className="inline-value">{patientData.gender}</Typography.Text>
                    </span>
                    <span className="detail-row">
                        <Typography.Text className="inline-label">AGE</Typography.Text> <Typography.Text className="inline-value">{patientData.age}</Typography.Text>
                    </span>
                    <span className="detail-row">
                        <Typography.Text className="inline-label">INCERTITUDE</Typography.Text>  
                        <span className="inline-rate">
                            <CertaintyRate filledQuantity={patientData.certainty} diameter={8} />
                        </span>
                    </span>
                </Col>

                <Col className="white-corner-col background-detail-column" xs={{ span: 15 }} lg={{ span: 15 }}>
                    <Typography.Title className="detail-column-title">
                        Fiche médicale
                    </Typography.Title>

                    <Typography.Title className="detail-column-label">
                        Mode de vie
                    </Typography.Title>
                    <Typography.Title className="detail-column-value">
                        {patientData.lifeStyle}
                    </Typography.Title>

                    <Typography.Title className="detail-column-label">
                        Antécédents familiaux
                    </Typography.Title>
                    <Typography.Title className="detail-column-value">
                        {patientData.familyHistory}
                    </Typography.Title>

                    <Typography.Title className="detail-column-label">
                        Antécédents médicaux
                    </Typography.Title>
                    <Typography.Title className="detail-column-value">
                        {patientData.medicalHistory}
                    </Typography.Title>

                    <Typography.Title className="detail-column-label">
                        Historique de la maladie
                    </Typography.Title>
                    <Typography.Title className="detail-column-value">
                        {patientData.diseaseHistory}
                    </Typography.Title>

                    <Typography.Title className="detail-column-label">
                        Allergies
                    </Typography.Title>
                    <Typography.Title className="detail-column-value">
                        {patientData.allergies}
                    </Typography.Title>

                    <Typography.Title className="detail-column-label">
                        Médicaments
                    </Typography.Title>
                    <Typography.Title className="detail-column-value">
                        {patientData.medicaments}
                    </Typography.Title>

                    <Typography.Title className="detail-column-label">
                        Remarques du médecin
                    </Typography.Title>
                    <Typography.Title className="detail-column-value">
                        {patientData.importantNotes}
                    </Typography.Title>
                </Col>
                { links }
            </Row>
        </>
    )

})
import React, {useState} from 'react'
import { view, autoEffect } from '@risingstack/react-easy-state';
import { Col, Row, Form, Input, Button, Modal, Divider } from 'antd';

import authStore from './authStore.js';
import Signup from './Signup.jsx';
import { useHistory } from "react-router-dom";

export default view(()=> {
  const history = useHistory();

    const onFinish = (values) => {
        authStore.signIn(values)
    };
    autoEffect(()=> {
        if(authStore.getUser) {
            history.push('/home')
        }
    }, [authStore.getUser])

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    
    return (
        <>
            <Row gutter={[16, 8]} justify="center" align="middle" className="">
                <Col id="landingPitch" xs={{ span: 20 }} md={{ span: 10 }} lg={{ span: 8 }}>
                    <h1>La plateforme qui facilite la prise de décision collégiale en situation d'incertitude</h1>
                    <p>NéoStaff vous permet de partager des questionnements et des avis sur des cas médicaux complexes en amont des Staffs.</p>
                </Col>
                <Col xs={{ span: 20 }} md={{ span: 10 }} lg={{ span: 8 }}>
                <Form
                id="login-form"
                name="normal_login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[{type: 'email', required: true, message: 'Adresse mail!' }]}
                    >
                        <Input placeholder="Adresse mail" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Mot de passe!' }]}
                    >
                        <Input
                        type="password"
                        placeholder="Mot de passe"
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" 
                            className="login-form-button login-form-button-filled">
                            Connexion
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button id="login-form-forgot" type="text">
                            Mot de passe oublié ?
                        </Button>
                    </Form.Item>

                    <Divider className="primary-divider">Ou</Divider>
                    <Form.Item>
                        <Button onClick={showModal} className="login-form-button login-form-button-inline">
                            S'inscrire
                        </Button>
                    </Form.Item>
                </Form>
                </Col>
            </Row>

            <Modal 
                className="neo-common-modal" 
                title="Informations proﬁl" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={null}
                >
                <Signup />
            </Modal>
        </>
    )
})
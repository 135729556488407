import { store } from '@risingstack/react-easy-state';
import axios from 'axios';
import jwt from "jsonwebtoken"
import { notification } from 'antd';
import { storage } from 'react-easy-params'
import configs from '../../app.config.js';
storage.user = storage.user || ""

const authStore = store({
    userScope: null,

    status: 'idle',
    success: null,
    error: null,
    get getUser(){
        return storage.user
    },

    async signUp( formData ) {
        const { firstName, lastName, email, password, rpps, profession, speciality, hospital, service } = formData;
        axios.post(`${configs.baseUrl}/auth/sign_up`, { 
            firstName, lastName, email, password, rpps, profession, speciality, hospital, service
        }).then(response => {
            const decodedToken = jwt.decode(response.data.token, {complete: true})
            authStore.userScope = decodedToken.payload.sub.scope;
            storage.user = decodedToken.payload.sub;
            localStorage.setItem('neo_staff_token', response.data.token)
            authStore.status = 'succeeded';
            authStore.success = 'Utilisateur enregistré avec succès.';
            notification.success({
                message: 'Success',
                description: authStore.success
            })
            return response;
        })
        .catch (error => {
            authStore.status = 'failed';
            authStore.success = null;
            if(error.response.status === 400) {
                authStore.error = "Impossible de valider les informations de profil."
            } else if(error.response.status === 401) {
                authStore.error = "RPPS invalide."
            } else {
                authStore.error = "Impossible d'enregistrer l'utilisateur."
            }
            console.log(error.response.data.message);
            notification.error({
                message: 'Erreur',
                description: authStore.error
            })
        })
    },
    async signIn( formData ) {
        const { email, password } = formData;
        axios.post(`${configs.baseUrl}/auth/sign_in`, { 
            email, password
        }).then(response => {
            const decodedToken = jwt.decode(response.data.token, {complete: true})
            authStore.userScope = decodedToken.payload.sub.scope;
            storage.user = decodedToken.payload.sub;
            localStorage.setItem('neo_staff_token', response.data.token)
            authStore.status = 'succeeded';
            authStore.success = 'Connexion réussie.';
            return response;
        })
        .catch (error => {
            console.log(error)
            authStore.status = 'failed';
            authStore.success = null;
            if(error.response.status === 401) {
                authStore.error = "Nom d'utilisateur ou mot de passe invalide!";
            } else {
                authStore.error = "Connexion impossible!"
                console.log(error.message);
            }
            notification.error({
                message: 'Erreur',
                description: authStore.error
            })
        })
    },
    async initialTokenControl() {
        const token = localStorage.getItem('neo_staff_token')
        if ( token ) {
            const config = {
                method: 'post',
                url: `${configs.baseUrl}/auth/verify_token`,
                headers: { 
                  'Authorization': `Bearer ${token}`
                }
            };
            axios(config)
            .then(response => {
                const decodedToken = jwt.decode(response.data.token, {complete: true})
                authStore.userScope = decodedToken.payload.sub.scope;
                storage.user = decodedToken.payload.sub;
                localStorage.setItem('neo_staff_token', response.data.token)
                authStore.status = 'succeeded';
                authStore.success = 'Connexion réussie.';
            })
            .catch (error => {
                authStore.status = 'failed';
                authStore.success = null;
                authStore.error = "Connexion impossible!"
                console.log(error.message);
            })
        }
    },
    signOut( ) {
        authStore.userScope = null;
        storage.user = null;
        localStorage.removeItem('neo_staff_token')
        authStore.status = 'succeeded';
    },
    async editProfile( formData ) {
        const token = localStorage.getItem("neo_staff_token")
        const config = {
            method: 'put',
            url: `${configs.baseUrl}/auth/edit/${storage.user._id}`,
            data: formData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            const decodedToken = jwt.decode(response.data.token, {complete: true})
            authStore.userScope = decodedToken.payload.sub.scope;
            storage.user = decodedToken.payload.sub;
            localStorage.setItem('neo_staff_token', response.data.token)
            authStore.status = 'succeeded';
            authStore.success = 'Votre profil a été mis à jour.';
            notification.success({
                message: 'Success',
                description: authStore.success
            })
        })
        .catch (error => {
            authStore.status = 'failed';
            authStore.success = null;
            authStore.error = "Impossible de mettre à jour les informations utilisateur"
            console.log("error.message");
            notification.error({
                message: 'Erreur',
                description: authStore.error
            })
        })
    },
    async changePassword( formData ) {
        const token = localStorage.getItem("neo_staff_token")
        const config = {
            method: 'put',
            url: `${configs.baseUrl}/auth/edit/${storage.user._id}/change_password`,
            data: formData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            const decodedToken = jwt.decode(response.data.token, {complete: true})
            authStore.userScope = decodedToken.payload.sub.scope;
            storage.user = decodedToken.payload.sub;
            localStorage.setItem('neo_staff_token', response.data.token)
            authStore.status = 'succeeded';
            authStore.success = 'Mot de passe utilisateur mis à jour avec succès.';
            notification.success({
                message: 'Success',
                description: authStore.success
            })
        })
        .catch (error => {
            authStore.status = 'failed';
            authStore.success = null;
            authStore.error = "Impossible de mettre à jour le mot de passe de l'utilisateur";
            console.log(error.message)
            notification.error({
                message: 'Erreur',
                description: authStore.error
            })
        })
    },
});

export default authStore
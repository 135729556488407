import React, { useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { Menu, Button, Typography, Avatar, Card } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { LogoutIcon } from '../../assets/icons/custom/logout';
import { view } from '@risingstack/react-easy-state';
import authStore from '../authentication/authStore.js'

export default view(() => {
    const user = authStore.getUser;
    let Rhistory = useHistory();
    let location = useLocation();
    
    const loadedPage = location.pathname.split("/")[1] || 'home';
    useEffect(()=> {
        authStore.initialTokenControl()
        .then(
            ()=> {
                if(user) {
                    const previousPath = location.pathname 
                    Rhistory.push(previousPath)
                }
            }
        );
    }, [])

    if ( user ) {
        return (
            <>
                
                <Typography.Title id="navBrand">
                    <Link to={'/home'}>
                        NéoStaff
                    </Link>
                </Typography.Title>
                <Menu id="neoNav" selectedKeys={[loadedPage]} mode="horizontal">
                    <Menu.Item key="navAvatar">
                        <Link to={'/profile'}>
                            <Card className="navAvatar">
                                <Card.Meta
                                    avatar={<Avatar size="35" icon={<UserOutlined />} />}
                                    title={ `${user.firstName} ${user.lastName}`}
                                    description={ `${user.speciality}`}
                                >
                                </Card.Meta>
                            </Card>
                        </Link>
                        
                    </Menu.Item>
                    <Menu.Item key="signout">
                        <Button id="signOutBtn" onClick={authStore.signOut}>
                            <LogoutIcon />
                        </Button>
                    </Menu.Item>
                </Menu>
            </>
        )
    } else {
        return (
            <>
                <Typography.Title id="navBrand" className="logo">
                    <Link to={'/'}>
                        NéoStaff
                    </Link>
                </Typography.Title>
                {/* <Menu id="neoNav" selectedKeys={[loadedPage]} mode="horizontal">

                    <Menu.Item key="home">
                        <Link to={'/'}>
                            Acueil
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="plateforme">
                        <Link to={'/plateforme'}>
                            Plateforme
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="staff">
                        <Link to={'/staff'}>
                            Staff
                        </Link>
                    </Menu.Item>
                </Menu> */}
            </>
        )
    }
})

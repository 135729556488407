import { store } from '@risingstack/react-easy-state';
import axios from 'axios';
import { notification } from 'antd';
import staffStore from '../staff/staffStore.js';
import { storage } from 'react-easy-params'

import configs from '../../app.config.js';

const caseStore = store({
    status: 'idle',
    success: null,
    error: null,
    subjectCase: null,
    async createCase( formData, meetingId ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            caseStore.status = 'failed';
            caseStore.success = null;
            caseStore.error = "Impossible de trouver un jeton!";
            return;
        }
        let processedData = formData;
        if(!formData.clinicalExamLinks) {
            processedData.clinicalExamLinks = [];
        }
        if(!formData.diagnosisList) {
            processedData.diagnosisList = [];
        }
        const config = {
            method: 'post',
            url: `${configs.baseUrl}/meeting/${meetingId}/case`,
            data: processedData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            caseStore.status = 'succeeded';
            caseStore.success = 'Le cas a été créé avec succès.';
            notification.success({
                message: 'Success',
                description: caseStore.success
            })
            let theMeeting = storage.fetchedStaff.find(item => item._id === meetingId);
            storage.fetchedStaff.filter(item => item._id === meetingId)[0].caseList.push(response.data.newCase)
            var index = storage.fetchedStaff.findIndex((item) => item._id === meetingId);
            storage.fetchedStaff.splice(index, 1);
            storage.fetchedStaff.push(theMeeting)
            return response
        })
        .catch (error => {
            console.log(error)
            caseStore.status = 'failed';
            caseStore.success = null;
            if(error.response.status === 401) {
                caseStore.error = "Demande non autorisée!";
            } else {
                console.error(error.response.data.message)
                caseStore.error = "Impossible de créer le cas!";
            }
            notification.error({
                message: 'Erreur',
                description: caseStore.error
            })
        })
    },

    async submitVote ( formData ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            caseStore.status = 'failed';
            caseStore.success = null;
            caseStore.error = "Impossible de trouver un jeton!";
            return;
        }
        const meetingId = caseStore.subjectCase.meeting;
        const caseId = caseStore.subjectCase._id;
        const config = {
            method: 'post',
            url: `${configs.baseUrl}/meeting/${meetingId}/case/${caseId}/vote`,
            data: formData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            caseStore.status = 'succeeded';
            caseStore.success = 'Soumis avec succès vos votes.';
            notification.success({
                message: 'Success',
                description: caseStore.success
            });
            caseStore.subjectCase = null;
            caseStore.subjectCase = response.data.foundCase;
            return response;
        })
        .catch (error => {
            console.log(JSON.stringify(error))
            caseStore.status = 'failed';
            caseStore.success = null;
            if(error.response.status === 401) {
                caseStore.error = "Demande non autorisée!";
            } else {
                caseStore.error = "Impossible de soumettre vos votes"
                console.log(error.response.data.message);
            }
            notification.error({
                message: 'Erreur',
                description: caseStore.error
            })
        })
    },

    async submitVoteAndNewDiagnose ( formData ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            caseStore.status = 'failed';
            caseStore.success = null;
            caseStore.error = "Impossible de trouver un jeton!";
            return;
        }
        const meetingId = caseStore.subjectCase.meeting;
        const caseId = caseStore.subjectCase._id;
        const config = {
            method: 'post',
            url: `${configs.baseUrl}/meeting/${meetingId}/case/${caseId}/diagnose`,
            data: formData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            caseStore.status = 'succeeded';
            caseStore.success = 'Ajout réussi des votes et du nouveau diagnostic.';
            notification.success({
                message: 'Success',
                description: caseStore.success
            });
            caseStore.subjectCase = null;
            caseStore.subjectCase = response.data.foundCase;
            return response;
        })
        .catch (error => {
            console.log(JSON.stringify(error))
            caseStore.status = 'failed';
            caseStore.success = null;
            if(error.response.status === 401) {
                caseStore.error = "Demande non autorisée!";
            } else {
                caseStore.error = "Impossible de soumettre les votes et d'ajouter le nouveau diagnostic"; 
                console.log(error.response.data.message);
            }
            notification.error({
                message: 'Erreur',
                description: caseStore.error
            })
        })
    },

    async editCase( formData, meetingId, caseId ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            caseStore.status = 'failed';
            caseStore.success = null;
            caseStore.error = "Impossible de trouver un jeton!";
            return;
        }
        let processedData = formData;
        if(!formData.clinicalExamLinks) {
            processedData.clinicalExamLinks = [];
        }
        if(!formData.diagnosisList) {
            processedData.diagnosisList = [];
        }
        const config = {
            method: 'put',
            url: `${configs.baseUrl}/meeting/${meetingId}/case/${caseId}`,
            data: processedData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            caseStore.status = 'succeeded';
            caseStore.success = 'Modification du cas avec succès.';
            notification.success({
                message: 'Success',
                description: caseStore.success
            })
            caseStore.subjectCase =response.data.updatedCase
            return response
        })
        .catch (error => {
            console.log(error)
            caseStore.status = 'failed';
            caseStore.success = null;
            if(error.response.status === 401) {
                caseStore.error = "Demande non autorisée!";
            } else {
                console.error(error.response.data.message)
                caseStore.error = "Impossible de modifier le cas!";
            }
            notification.error({
                message: 'Erreur',
                description: caseStore.error
            })
        })
    },

});

export default caseStore
import React from "react";
import { view } from '@risingstack/react-easy-state';
import authStore from '../authentication/authStore.js';

import { Redirect, Route } from "react-router";

export default view ((props) => {
    const token = localStorage.getItem("neo_staff_token")

    const { type } = props;
    if (type === "public" && authStore.getUser ) {
        return (
            <Redirect to="/home" />
        )
    }   else if (type === "user" && !authStore.getUser &&  !token) {
        return (
            <Redirect to="/" />
        )
    }
    return <Route {...props} />;
})
import React, {useRef, useState, useEffect} from 'react';
import { view } from '@risingstack/react-easy-state';
import { Col, Row, Form, Input, Select, Typography, Button, Radio, Space } from 'antd';
import { PlusCircleFilled, MinusCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

export default view((props)=> {
    const { defaults, form } = props;
    const init = useRef(null)
    const [customId, setCustomId] = useState("")
    const httpUrlValidation = async (rule, value) => {
        if(value) {
            const urlRegex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
            if (!urlRegex.test(value)) {
                throw new Error("Veuillez saisir une URL valide commençant par HTTP ou HTTPS!")
            }
        }
    }
    const uuidv4 = () => {
        return 'yxxx-xx'.replace(/[xy]/g, function(c) {
          var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
    }

    useEffect(()=> {
        const listVal = form.getFieldValue("clinicalExamLinks")
        if(!listVal) {
            init.current.click();
        }
        const newUUID = uuidv4()
        setCustomId(newUUID)
        if(!defaults){
            form.setFieldsValue({'caseId': newUUID})
        }
    }, [form])
    return (
        <>
            <Row align="top" gutter={40}>
                <Col span={12}>
                    <Typography.Title className="formSectionLabel">
                        Informations patient
                    </Typography.Title>
                    <Form.Item
                        name="caseId"
                        rules={[
                        {
                            required: true,
                            message: 'Nom du Staff!',
                        },
                        ]}
                        initialValue={defaults ? defaults.caseId : customId}
                        >
                        <Input 
                        disabled placeholder="Nom du Staff"/>
                    </Form.Item>

                    <Form.Item
                        name="age"
                        rules={[
                        {
                            required: true,
                            message: "Tranche d'âge!",
                        },
                        ]}
                        initialValue={defaults ? defaults.age : null}
                    >
                        <Select placeholder="Tranche d'âge">
                            <Option value="< 18 ans">{"< 18 ans"}</Option>
                            <Option value="19 - 25 ans">19 - 25 ans</Option>
                            <Option value="26 – 35 ans">26 – 35 ans</Option>
                            <Option value="36 – 50 ans">36 – 50 ans</Option>
                            <Option value="51 – 60 ans">51 – 60 ans</Option>
                            <Option value="61 – 70 ans">61 – 70 ans</Option>
                            <Option value="71 – 80 ans">71 – 80 ans</Option>
                            <Option value="> 81 ans">{"> 81 ans"}</Option>
                        </Select>

                    </Form.Item>
                    <Form.Item
                        name="gender"
                        rules={[
                        {
                            required: true,
                            message: 'Sexe',
                        },
                        ]}
                        initialValue={defaults ? defaults.gender : null}
                    >
                        <Select placeholder="Sexe">
                            <Option value='Femme'>Femme</Option>
                            <Option value='Homme'>Homme</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="lifeStyle"
                        rules={[
                        {
                            required: true,
                            message: 'Renseignez quelques informations sur son mode de vie (status marital, travail, lieu de vie etc.)',
                        },
                        ]}
                        initialValue={defaults ? defaults.lifeStyle : null}
                    >
                        <Input.TextArea autoSize placeholder="Mode de vie"  ></Input.TextArea>

                    </Form.Item>
                    <Form.Item
                        name="familyHistory"
                        rules={[
                        {
                            message: 'Antécédents familiaux!',
                        },
                        ]}
                        initialValue={defaults ? defaults.familyHistory : null}
                    >
                        <Input.TextArea autoSize placeholder="Antécédents familiaux"  ></Input.TextArea>
                    </Form.Item>
                    <Form.Item
                        name="medicalHistory"
                        rules={[
                        {
                            message: 'Antécédents médicaux!',
                        },
                        ]}
                        initialValue={defaults ? defaults.medicalHistory : null}
                    >
                        <Input.TextArea autoSize placeholder="Antécédents médicaux"></Input.TextArea>
                    </Form.Item>
                    <Form.Item
                        name="diseaseHistory"
                        rules={[
                        {
                            message: 'Historique de la maladie!',
                        },
                        ]}
                        initialValue={defaults ? defaults.diseaseHistory : null}
                    >
                        <Input.TextArea autoSize placeholder="Historique de la maladie"></Input.TextArea>
                    </Form.Item>
                    <Form.Item
                        name="allergies"
                        rules={[
                        {
                            message: 'Allergies!',
                        },
                        ]}
                        initialValue={defaults ? defaults.diseaseHistory : null}
                    >
                        <Input.TextArea autoSize placeholder="Allergies"></Input.TextArea>
                    </Form.Item>
                    <Form.Item
                        name="medicaments"
                        rules={[
                        {
                            message: 'Médicaments!',
                        },
                        ]}
                        initialValue={defaults ? defaults.diseaseHistory : null}
                    >
                        <Input.TextArea autoSize placeholder="Médicaments"></Input.TextArea>
                    </Form.Item>
                    <Form.Item
                        name="importantNotes"
                        rules={[
                        {
                            required: true,
                            message: 'Renseignez ce qui vous pose probléme sur ce cas',
                        },
                        ]}
                        initialValue={defaults ? defaults.importantNotes : null}
                    >
                        <Input.TextArea autoSize placeholder="Remarques"></Input.TextArea>
                    </Form.Item>
                </Col>
                <Col span={12}>
                <Typography.Title className="formSectionLabel">
                    Lien vers le dossier médical
                </Typography.Title>
                <Form.List name="clinicalExamLinks">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                        <Space key={field.key} style={{ display: 'flex' }} align="baseline">
                            <Form.Item
                                {...field}
                                name={[field.name, 'link']}
                                fieldKey={[field.fieldKey, 'link']}
                                rules={[
                                    { required: true, message: 'lien' },
                                    { validator: httpUrlValidation }
                                ]}>
                                <Input.TextArea autoSize placeholder="Lien"></Input.TextArea>
                            </Form.Item>
                            <Form.Item
                            {...field}
                            name={[field.name, 'description']}
                            fieldKey={[field.fieldKey, 'description']}
                            rules={[{ required: true, message: 'écrivez un commentaire sur cet examen' }]}
                            >
                                <Input.TextArea autoSize placeholder="Description"></Input.TextArea>

                            </Form.Item>
                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                        ))}
                        <Form.Item>
                        <Button ref={init} id="addLinkToCaseBtn" onClick={() => add()} block icon={<PlusCircleFilled />}>
                            Ajouter un examen clinique ou paraclinique
                        </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                    <Typography.Title className="radioLabel">
                        Quel est votre niveau d'incertitude sur ce cas?
                    </Typography.Title>
                    <Form.Item
                        name="certainty"
                        rules={[
                        {
                            required: true,
                            message: "Quel est votre niveau d'incertitude sur ce cas?",
                        },
                        ]}
                        initialValue={defaults ? defaults.certainty : null}
                    >
                        <Radio.Group>
                            <Radio className="vertical-radio"  value={1}>
                                j'hésite entre deux options
                            </Radio>
                            <Radio className="vertical-radio" value={2}>
                                j'ai plusieurs options
                            </Radio>
                            <Radio className="vertical-radio" value={3}>
                                je n'ai pas d'idée
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
})
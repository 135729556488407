import React, { useState } from 'react';
import { view } from '@risingstack/react-easy-state';
import { Button, Form, Typography, Input, Collapse, Slider, Popconfirm } from 'antd';
import { DownCircleFilled, CheckCircleFilled, PlusCircleFilled, MessageOutlined } from '@ant-design/icons';
import caseStore from './caseStore.js';

export default view(()=> {
    const [form] = Form.useForm();
    const [newDiagnoseForm] = Form.useForm();
    const [opinionField, setOpinionField] = useState(null)
    const [showNewDiagnoseField, setShowNewDiagnoseField] = useState(true)
    const [castedDiagnose, setCastedDiagnose] = useState(<></>)
    
    const cancelButtonProps = {
        className: "cancel-delete"
    }
    const okButtonProps = {
        primary: "true",
        className: "approve-delete"
    }

    const diagnoseList = caseStore.subjectCase.diagnosisList.map( item => (
        <>
            <Typography.Title className="possibility-label">
                {item.probableDiagnosis}
            </Typography.Title>
                <span className="vote-slider-wrapper">
                    <span className="left-label">pas sûr du tout</span>
                        <Form.Item
                        name={`existingDiagnoseVote_${item._id}`}
                        rules={[{ required: true, message: 'Niveau de certitude' }]}
                        >
                                <Slider min={0} max={100} tooltipVisible={false} />
                        </Form.Item>
                <span className="right-label">très sûr</span>
            </span>
            <Collapse
            className="add-comment-panel" ghost
            expandIconPosition="right"
            expandIcon={({ isActive }) =><MessageOutlined />}
            bordered={false} >
                <Collapse.Panel key="1" >
                <Form.Item
                name={`existingDiagnoseComment_${item._id}`}
                >
                    <Input placeholder="Ajouter un commentaire" />
                </Form.Item>
                </Collapse.Panel>
            </Collapse>
        </>
    ))

    const handleFormData = (values) => {
        const formKeys = Object.keys(values);
        const newVotes = [];
        const newComments = [];
    
        formKeys.forEach( item => {
            if(item.startsWith("existingDiagnoseVote_")){
                const diagnoseId = item.split("existingDiagnoseVote_")[1];
                const vote = values[item]
                newVotes.push({diagnoseId, vote})
            } else if (item.startsWith("existingDiagnoseComment_")) {
                const diagnoseId = item.split("existingDiagnoseComment_")[1];
                const comment = values[item]
                newComments.push({diagnoseId, comment})
            }
        })
        return { votes: newVotes, comments: newComments}
    }

    const handleVoteSubmission = (values) => {
        const processedFormValues = handleFormData(values)
        caseStore.submitVote(processedFormValues).then(()=>{
            console.log(caseStore.subjectCase);
        })
    }

    const castNewDiagnose = () => {
        setShowNewDiagnoseField(false)
        setCastedDiagnose(
            <>
                <Form 
                    form={newDiagnoseForm}
                    className="vote-parent-form"
                >
                    <Typography.Title className="possibility-label">
                        {opinionField}
                    </Typography.Title>
                    <span className="vote-slider-wrapper">
                        <span className="left-label">pas sûr du tout</span>
                            <Form.Item
                            name="vote"
                            rules={[{ required: true, message: 'Niveau de certitude' }]}
                            >
                                        <Slider min={0} max={100} tooltipVisible={false} />
                            </Form.Item>
                        <span className="right-label">très sûr</span>
                    </span>
                    <Collapse
                    className="add-comment-panel" ghost
                    expandIconPosition="right"
                    expandIcon={({ isActive }) =><MessageOutlined />}
                    bordered={false} >
                        <Collapse.Panel key="1" >
                        <Form.Item
                        name="comment"
                        >
                            <Input placeholder="Ajouter un commentaire" />
                        </Form.Item>
                        </Collapse.Panel>
                    </Collapse>
                </Form>
            </>
        )
    }

    const handleNewDiagnoseSubmission = async () => {
            try {
                const newDiagnose = await newDiagnoseForm.validateFields();
                const validationResult = await form.validateFields();
                const processedFormValues = handleFormData(validationResult)
                processedFormValues['newDiagnose'] = newDiagnose
                processedFormValues.newDiagnose['name'] = opinionField
                
                await caseStore.submitVoteAndNewDiagnose(processedFormValues)
            } catch (error) {
                console.log(error)
            }
    }

    const voteform = (
        <>
            <Form
                onFinish={handleVoteSubmission}
                form={form}
                className="vote-parent-form"
            >
                {diagnoseList}
                <Form.Item style={{"width": "100%"}}>
                    <Button
                    id="submit-vote-button"
                    className={`${showNewDiagnoseField ? "visible" : "hidden"}`}
                    htmlType="submit"
                    icon={<CheckCircleFilled />}>
                        Valider mes résultats
                    </Button>                    
                </Form.Item>
            </Form>
        </>
    )


    return(
        <>
            <Typography.Title className="vote-column-header">QUESTION DU MÉDECIN EN CHARGE DU CAS</Typography.Title>
            <Typography.Title className="vote-column-opinion-title">Selon vous, quelle est le diagnostic le plus adapté ?</Typography.Title>
            <Input.TextArea 
                className={`give-opinion-text ${showNewDiagnoseField ? "visible" : "hidden"}`}
                placeholder="Écrivez votre ressenti…" 
                onChange={(event)=>setOpinionField(event.target.value)}
                rows={7} >

            </Input.TextArea>
            <Collapse ghost
            className="add-vote-panel"
            expandIcon={({ isActive }) =><DownCircleFilled rotate={isActive ? 180 : 0} />}
            bordered={false} >
                <Collapse.Panel key="1" >
                    <div id="add-diagnose-button-wrapper">
                        <Popconfirm
                        className="delete-popconfirm"
                        title="Êtes-vous sûre de votre proposition? Une fois ajoutée, vous ne pourrez plus la modifier?"
                        onConfirm={castNewDiagnose}
                        onCancel={(e)=>console.log(e)}
                        okText="Oui"
                        cancelText="Non"
                        cancelButtonProps={cancelButtonProps}
                        okButtonProps={okButtonProps}
                    >
                        <Button
                        id="add-diagnose-button"
                        disabled={!opinionField}
                        className={`${showNewDiagnoseField ? "visible" : "hidden"}`}
                        icon={<PlusCircleFilled />}>
                            Ajouter ma proposition
                        </Button>                   
                    </Popconfirm>
                    </div>
                    {castedDiagnose}
                    {voteform}
                    <Button
                    id="submit-vote-and-diagnose-button"
                    className={`${!showNewDiagnoseField ? "visible" : "hidden"}`}
                    onClick={handleNewDiagnoseSubmission}
                    icon={<CheckCircleFilled />}>
                        Valider mes résultats
                    </Button>                   
                </Collapse.Panel>
            </Collapse>
        </>
    )
})
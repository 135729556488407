import { store } from '@risingstack/react-easy-state';
import axios from 'axios';
import { notification } from 'antd';
import authStore from '../authentication/authStore.js';
import configs from '../../app.config.js';
import { storage } from 'react-easy-params'

storage.fetchedStaff = storage.fetchedStaff || []

const staffStore = store({
    status: 'idle',
    success: null,
    error: null,
    get fetchedStaffList(){
        return storage.fetchedStaff
    },
    async fetchStaffList( ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            staffStore.status = 'failed';
            staffStore.success = null;
            staffStore.error = "Impossible de trouver un jeton!";
            return;
        }
        const config = {
            method: 'get',
            url: `${configs.baseUrl}/meeting`,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            storage.fetchedStaff = response.data.meetingList;
            staffStore.status = 'succeeded';
            staffStore.success = 'Liste du staff récupérée avec succès.';
            return response
        })
        .catch (error => {
            staffStore.status = 'failed';
            staffStore.success = null;
            if(error.response.status === 401) {
                staffStore.error = "Demande non autorisée!";
            } else {
                staffStore.error = "Impossible de récupérer la liste du staff"
                console.error(error.response.data.message);
            }
            notification.error({
                message: 'Erreur',
                description: staffStore.error
            })
        })
    },

    async createStaff( formData ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            staffStore.status = 'failed';
            staffStore.success = null;
            staffStore.error = "Impossible de trouver un jeton!";
            return;
        }
        formData['speciality'] = authStore.getUser.speciality;
        const config = {
            method: 'post',
            url: `${configs.baseUrl}/meeting`,
            data: formData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            storage.fetchedStaff.unshift(response.data.newMeeting);
            staffStore.status = 'succeeded';
            staffStore.success = "Le staff a bien été créé" 
            notification.success({
                message: 'Success',
                description: staffStore.success
            })
            return response
        })
        .catch (error => {
            staffStore.status = 'failed';
            staffStore.success = null;
            if(error.response.status === 401) {
                staffStore.error = "Demande non autorisée!";
            } else {
                staffStore.error = "Impossible de créer le staff"
                console.error(error.response.data.message);
            }
            notification.error({
                message: 'Erreur',
                description: staffStore.error
            })
        })
    },

    async editStaff( formData ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            staffStore.status = 'failed';
            staffStore.success = null;
            staffStore.error = "Impossible de trouver un jeton!";
            return;
        }
        formData['speciality'] = authStore.getUser.speciality;

        const config = {
            method: 'put',
            url: `${configs.baseUrl}/meeting/${formData.meetingId}`,
            data: formData,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            var index = storage.fetchedStaff.findIndex((item) => item._id === formData.meetingId);
            storage.fetchedStaff.splice(index, 1);
            storage.fetchedStaff.splice(index, 0, response.data.updatedMeeting);
            staffStore.status = 'succeeded';
            staffStore.success = "Modification de le staff avec succès";
            console.log(response.data)
            notification.success({
                message: 'Success',
                description: staffStore.success
            })
            return response
        })
        .catch (error => {
            staffStore.status = 'failed';
            staffStore.success = null;
            if(error.response.status === 401) {
                staffStore.error = "Demande non autorisée!";
            } else {
                staffStore.error = "Impossible de modifier le staff"
                console.error(error.response.data.message);
            }
            notification.error({
                message: 'Erreur',
                description: staffStore.error
            })
        })
    },

    async deleteStaff( meeting_id ) {
        const token = localStorage.getItem('neo_staff_token')
        if(!token) {
            staffStore.status = 'failed';
            staffStore.success = null;
            staffStore.error = "Impossible de trouver un jeton!";
            return;
        }
        const config = {
            method: 'delete',
            url: `${configs.baseUrl}/meeting/${meeting_id}`,
            headers: { 
              'Authorization': `Bearer ${token}`
            }
        };
        axios(config)
        .then(response => {
            var index = storage.fetchedStaff.findIndex((item) => item._id === meeting_id);
            storage.fetchedStaff.splice(index, 1);
            staffStore.status = 'succeeded';
            staffStore.success = "Staff supprimée avec succès";
            notification.success({
                message: 'Success',
                description: staffStore.success
            })
            return response
        })
        .catch (error => {
            staffStore.status = 'failed';
            staffStore.success = null;
            if(error.response.status === 401) {
                staffStore.error = "Demande non autorisée!";
            } else {
                staffStore.error = "Impossible de supprimer le staff"
                console.error(error.response.data.message);
            }
            notification.error({
                message: 'Erreur',
                description: staffStore.error
            })
        })
    }

});

export default staffStore
import React from 'react';
import { view } from '@risingstack/react-easy-state';

export default view((props)=> {
    const { filledQuantity, diameter } = props;

    const rates = []
    for(let i=1; i<=3; i++){
        if(i<=filledQuantity){
            rates.push(<span style={{"margin": diameter/2, width:diameter, height:diameter}} className="certainty-rate--filled"></span>)
        } else {
            rates.push(<span style={{"margin": diameter/2, width:diameter, height:diameter}} className="certainty-rate--empty"></span>)
        }
    }
    return (
        <>
            <div className="rate-wrapper">
                {rates}
            </div>
        </>
    )
})
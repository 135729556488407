import React, { useState } from 'react';
import { view } from '@risingstack/react-easy-state';
import FullProfileForm from './FullProfileForm.jsx';
import ChangePasswordForm from './ChangePasswordForm.jsx';
import authStore from './authStore.js';
import { Col, Row, Button, Modal, Typography } from 'antd';

export default view(()=> {
    const user = authStore.getUser
    const handleSubmit = (values) => {
        authStore.editProfile(values).then(() => {
            handleCancel()
        })
    }
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    const showPasswordModal = () => {
        setIsPasswordModalVisible(true);
    };
    
    const handlePasswordCancel = () => {
        setIsPasswordModalVisible(false);
    };
    return (
        <>
            <Row className="page-row">
                <Col className="page-column" span={14} offset={3}>
                    <Typography className="page-title">
                        Mon proﬁl
                    </Typography>
                    <div>
                        <Typography className="page-sub-title page-sub-title-wob">
                            {/* Identité */}
                        </Typography>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Typography.Text className="silent-label">
                                    Nom
                                </Typography.Text>
                                <Typography.Text className="user-info">
                                    {user.firstName}
                                </Typography.Text>
                                <Typography.Text className="silent-label">
                                    Prénom
                                </Typography.Text>
                                <Typography.Text className="user-info">
                                    {user.lastName}
                                </Typography.Text>
                                <Typography.Text className="silent-label">
                                    Profession
                                </Typography.Text>
                                <Typography.Text className="user-info">
                                    {user.profession}
                                </Typography.Text>
                                <Typography.Text className="silent-label">
                                    Spécialité
                                </Typography.Text>
                                <Typography.Text className="user-info">
                                    {user.speciality}
                                </Typography.Text>
                                <Typography.Text className="silent-label">
                                    Numéro RPPS
                                </Typography.Text>
                                <Typography.Text className="user-info">
                                    {user.rpps}
                                </Typography.Text>
                            </Col>
                            <Col span={12}>
                                <Typography.Text className="silent-label">
                                    Hôpital
                                </Typography.Text>
                                <Typography.Text className="user-info">
                                    {user.hospital}
                                </Typography.Text>
                                <Typography.Text className="silent-label">
                                    Service
                                </Typography.Text>
                                <Typography.Text className="user-info">
                                    {user.service}
                                </Typography.Text>
                            </Col>
                        </Row>
                    </div>
                    <div id="profile-button-wrapper">
                        <Button onClick={showModal} id="modify-profile-btn">
                            Modifier le profil
                        </Button>
                        <Button onClick={showPasswordModal} id="change-password-btn">
                            Modiﬁer le mot de passe
                        </Button>
                    </div>
                </Col>
            </Row>
            <Modal 
                className="neo-common-modal" 
                title="Informations proﬁl" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={null}
                >
                <FullProfileForm defaults={user} handleSubmit={handleSubmit} />
            </Modal>
            <Modal 
                className="neo-common-modal" 
                title="Modifier mot de passe" 
                visible={isPasswordModalVisible} 
                onCancel={handlePasswordCancel}
                footer={null}
                >
                <ChangePasswordForm />
            </Modal>
        </>
    )
})
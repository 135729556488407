import { view } from '@risingstack/react-easy-state';
import { Col, Row, Form, Input, Button, Select, Typography } from 'antd';
import { useHistory } from "react-router-dom";

import authStore from './authStore.js';
const { Option } = Select;

export default view(()=> {
    const history = useHistory();

    const strongPasswordValidation = async (rule, value) => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        if (!strongRegex.test(value)) {
            throw new Error("Le mot de passe doit contenir au moins 1 minuscule, 1 majuscule, 1 caractère numérique et 8 caractères!")
        }
    }

    const onFinish = (values) => {
        authStore.signUp(values)
        .then(
            ()=> {
              if(authStore.getUser) {
                history.push('/home')
              }
            }
        );
    };

    return (
        <>
            <Form 
                onFinish={onFinish}
            >
                <Row align="bottom">
                    <Col span={12}>
                        <Typography.Title className="formSectionLabel">
                            Identiﬁcation
                        </Typography.Title>
                        <Form.Item
                            name="firstName"
                            rules={[
                            {
                                required: true,
                                message: 'Nom!',
                            },
                            ]}
                        >
                            <Input placeholder="Nom" />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            rules={[
                            {
                                required: true,
                                message: 'Prénom!',
                            },
                            ]}
                        >
                            <Input placeholder="Prénom" />
                        </Form.Item>



                        <Form.Item
                            name="rpps"
                            rules={[
                            {
                                required: true,
                                message: 'Numéro RPPS!',
                            },
                            ]}
                        >
                            <Input placeholder="Numéro RPPS" />
                        </Form.Item>

                        <Form.Item
                            name="profession"
                            rules={[
                            {
                                required: true,
                                message: 'Profession!',
                            },
                            ]}
                        >
                            <Input placeholder="Profession" />
                        </Form.Item>

                        <Form.Item
                            name="speciality"
                            rules={[
                            {
                                required: true,
                                message: 'Spécialité!',
                            },
                            ]}
                        >
                            <Select placeholder="Spécialité">
                                <Option value='Allergie et immunologie'>Allergie et immunologie</Option>
                                <Option value='Anesthésiologie'>Anesthésiologie</Option>
                                <Option value='Dermatologie'>Dermatologie</Option>
                                <Option value='Radiologie diagnostique'>Radiologie diagnostique</Option>
                                <Option value="Médecine d'urgence">Médecine d'urgence</Option>
                                <Option value='Médecine familiale'>Médecine familiale</Option>
                                <Option value='Médecine interne'>Médecine interne</Option>
                                <Option value='Génétique médicale'>Génétique médicale</Option>
                                <Option value='Neurologie'>Neurologie</Option>
                                <Option value='Médecine nucléaire'>Médecine nucléaire</Option>
                                <Option value='Obstétrique et de gynécologie'>Obstétrique et de gynécologie</Option>
                                <Option value='Ophtalmologie'>Ophtalmologie</Option>
                                <Option value='Pathologie'>Pathologie</Option>
                                <Option value='Pédiatrie'>Pédiatrie</Option>
                                <Option value="Médecine physique et réadaptation">Médecine physique et réadaptation</Option>
                                <Option value='Médecine préventive'>Médecine préventive</Option>
                                <Option value='Psychiatrie'>Psychiatrie</Option>
                                <Option value="Radio-oncologie">Radio-oncologie</Option>
                                <Option value='Opération'>Opération</Option>
                                <Option value='Urologie'>Urologie</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="hospital"
                            rules={[
                            {
                                required: true,
                                message: 'Hôpital!',
                            },
                            ]}
                        >
                            <Input placeholder="Hôpital" />
                        </Form.Item>

                        <Form.Item
                            name="service"
                            rules={[
                            {
                                required: true,
                                message: 'Service!',
                            },
                            ]}
                        >
                            <Input placeholder="Service" />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            rules={[
                            {
                                type: 'email',
                                message: 'Adresse!',
                            },
                            {
                                required: true,
                                message: 'Adresse!',
                            },
                            ]}
                        >
                            <Input placeholder="Adresse mail" />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                            {
                                required: true,
                                message: 'Mot de passe!',
                            },
                            { validator: strongPasswordValidation }
                            ]}
                        >
                            <Input type="password" placeholder="Mot de passe" />
                        </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item className="submitBtnFormItem">
                        <Button htmlType="submit" 
                            className="full-profile-form-button edit-profile-button">
                            Valider
                        </Button>
                    </Form.Item>
                </Col>
                </Row>
            </Form>
        </>
    )
})
import { view } from '@risingstack/react-easy-state';
import { Col, Row, Form, Input, Button, TimePicker, Typography, DatePicker, Select } from 'antd';
import authStore from '../authentication/authStore.js';
import moment from 'moment';
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';

const { Option } = Select;

export default view((props)=> {
    const [form] = Form.useForm();
    const { handleSubmit, defaults } = props;
    function range(start, end) {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    }
    const disabledDate = (current)=>{
        return current && current < moment().subtract(1, 'day');
    }

    let night = range(0, 8);
    let night2 = range(21, 24);

    let durationOptions = [<Option value={`0:30`}>0:30</Option>];

    const disabledHours = (current) => {
        const meetingDate = form.getFieldValue("date")
        const allHours = night.concat(night2)
        if(meetingDate){
            const isMeetingToday = !meetingDate.isAfter(moment(), "day")
            if(isMeetingToday){
                const currentHour = meetingDate.hour()
                const hoursTillNow = range(8, currentHour+1) 
                const todaysDisabledHours = allHours.concat(hoursTillNow)
                return todaysDisabledHours
            }
        }
        return allHours
    }

    for(let i=1; i<=8; i++){
        durationOptions.push(<Option value={`${i}:00`}>{i}:00</Option>);
        durationOptions.push(<Option value={`${i}:30`}>{i}:30</Option>);
    }

    return (
        <>
            <Form
                form={form}
                onFinish={(values) => handleSubmit(values).then(()=>form.resetFields())}
            >
                <Row align="top" gutter={40}>
                    <Col span={12}>
                        <Typography.Title className="formSectionLabel">
                            Quoi ?
                        </Typography.Title>
                        <Form.Item
                            name="name"
                            rules={[
                            {
                                required: true,
                                message: 'Nom du Staff!',
                            },
                            ]}
                            initialValue={defaults ? defaults.name : null}
                        >
                            <Input placeholder="Nom du Staff" />
                        </Form.Item>
                        <Form.Item
                            name="description"
                            rules={[
                            {
                                required: true,
                                message: 'Contenu!',
                            },
                            ]}
                            initialValue={defaults ? defaults.description : null}
                        >
                            <Input placeholder="Contenu" />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Typography.Title className="formSectionLabel">
                            Où ?
                        </Typography.Title>
                        <Form.Item
                            name="hospital"
                            rules={[
                            {
                                required: true,
                                message: 'Hôpital!',
                            },
                            ]}
                            initialValue={defaults ? defaults.hospital : authStore.getUser.hospital}
                            // initialValue={authStore.getUser.hospital}
                        >
                            <Input 
                            // disabled={defaults ? true : false} 
                            disabled={true} 
                            placeholder="Hôpital" />
                        </Form.Item>
                        <Form.Item
                            name="service"
                            rules={[
                            {
                                required: true,
                                message: 'Service!',
                            },
                            ]}
                            initialValue={defaults ? defaults.service : authStore.getUser.service}
                        >
                            <Input 
                            disabled={true} 
                            placeholder="Service" />
                        </Form.Item>
                        <Form.Item
                            className="lastFormItemInSection"
                            name="room"
                            rules={[
                            {
                                required: true,
                                message: 'Salle!',
                            },
                            ]}
                            initialValue={defaults ? defaults.room : null}
                        >
                            <Input placeholder="Salle" />
                        </Form.Item>
                        <Typography.Title className="formSectionLabel">
                            Quand ?
                        </Typography.Title>
                        <Form.Item
                            name="date"
                            rules={[
                                {
                                    required: true,
                                    message: 'Date!',
                                },
                            ]}
                            initialValue={defaults ? moment(defaults.startTime) : null}
                            >
                            <DatePicker
                                placeholder="Date"
                                disabledDate={disabledDate}
                                locale={locale}
                                format='DD/MM/YYYY'
                            />
                        </Form.Item>
                        <Form.Item
                            name="startTime"
                            dependencies={['date']}
                            shouldUpdate
                            rules={[
                                {
                                    required: true,
                                    message: 'Horaire - Début!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        const dateValue = getFieldValue('date')
                                        if(!dateValue) return Promise.reject(new Error("Veuillez d'abord sélectionner une date!"));
                                        const isMeetingToday = !dateValue.isAfter(moment(), "day")
                                        if (dateValue && isMeetingToday && !dateValue.isAfter(value, "hour")) {
                                            return Promise.resolve();
                                        } else if (dateValue && !isMeetingToday) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('Les deux mots de passe saisis ne correspondent pas!'));
                                    },
                                }),
                            ]}
                            initialValue={defaults ? moment(defaults.startTime) : null}

                            >
                            <TimePicker
                                format="HH:mm"
                                minuteStep={15}
                                hideDisabledOptions showNow={false}
                                disabledHours={disabledHours}
                                placeholder="Horaire - Début!" />
                        </Form.Item>
                        <Form.Item
                            className="lastFormItemInSection"
                            name="duration"
                            rules={[
                                {
                                    required: true,
                                    message: 'Durée en heure!',
                                },
                            ]}
                            initialValue={defaults ? defaults.duration : null}
                        >
                            <Select placeholder="Durée en heure">
                                {durationOptions}
                            </Select>

                        </Form.Item>

                        <Form.Item className="submitBtnFormItem">
                            <Button htmlType="submit" 
                                className="full-profile-form-button edit-profile-button">
                                Valider
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </>
    )
})
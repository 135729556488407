import { view } from '@risingstack/react-easy-state';
import caseStore from './caseStore.js';
import authStore from '../authentication/authStore.js';
import PresentVotes from './PresentVotes.jsx';
import AddVoteForm from './AddVoteForm.jsx';
import EditCase from './EditCase.jsx';

export default view(()=> {
    let content;
    let modifySection;

    const votes = caseStore.subjectCase.diagnosisList.map(item => item.vote)
    const isUserVoted = votes[0].find(item => item.voter === authStore.getUser._id);
    const isCaseCreator = authStore.getUser._id === caseStore.subjectCase.creator;

    if(isUserVoted || isCaseCreator) {
        content = <PresentVotes />
    } else {
        content = <AddVoteForm />
    }

    if(isCaseCreator){
        modifySection = <EditCase />
    }
    return (
        <>
            {content}
            {modifySection}
        </>
    )
})

import React, { useEffect, useState } from 'react';
import { view } from '@risingstack/react-easy-state';
import { Typography, Table, Modal, Button, Row, Col, Popconfirm } from 'antd';
import moment from 'moment';
import { InfoCircleOutlined, CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { useHistory } from "react-router-dom";

import CaseTable from '../case/CaseTable.jsx';
import caseStore from '../case/caseStore.js';
import authStore from '../authentication/authStore.js';

import staffStore from './staffStore.js';
import StaffDetail from './StaffDetail.jsx';

export default view(()=> {
    const [showStaffDetailModal, setShowStaffDetailModal] = useState(false);
    const [staffDetailModalContent, setStaffDetailModalContent] = useState(null);
    const [subjectStaffId, setSubjectStaffId] = useState(0);
    const [ isEditMode, setEditMode ] = useState(false);
    const history = useHistory();

    const cancelButtonProps = {
        className: "cancel-delete"
    }
    const okButtonProps = {
        danger: "true",
        className: "approve-delete"
    }
    const handleDeleteStaff = () => {
        // console.log(staffDetailModalContent._id)
        staffStore.deleteStaff(staffDetailModalContent._id)
        .then( () => {
            history.push("/")
        })
        .catch( () => {
            history.push("/home")
        })
    }

    const theMeeting = staffStore.fetchedStaffList.find(item => item._id === subjectStaffId);
    const lenOfSub = theMeeting ? theMeeting.caseList.length : 0;
    useEffect(()=> {
        staffStore.fetchStaffList();
        handleCancel();
    }, [staffStore.success, lenOfSub]);

    const openStaffDetail = (record) => {
        setStaffDetailModalContent(record);
        setShowStaffDetailModal(true);
    };

    const handleCancel = () => {
        setShowStaffDetailModal(false);
        setEditMode(false)
    };

    const tableData = staffStore.fetchedStaffList;
    tableData.forEach(item => item["key"] = item.id);

    let content;
    const columns = [
        { title: 'Nom du staff', dataIndex: 'name', key: 'name' },
        { title: 'Nb de cas', dataIndex: 'caseList', key: 'caseListLength',
            render: (text, record) => (record.caseList.length)
        },
        { title: 'Date', dataIndex: 'startTime', key: 'date',
            render: (text, record) => {
                const date = moment(record.startTime).format("DD/MM/YYYY");
                return date;
            } 
        },
        { title: 'Heure', dataIndex: 'startTime', key: 'startTime',
            render: (text, record) => {
                const date = moment(record.startTime).format("HH:mm");
                return date;
            } 
        },
        { title: 'Statut', dataIndex: '', key: 'status', render: (text, record) => {
            let content;
            record.meetingStatus 
                ? content = <CheckCircleFilled className="status-icon icon-check" title="Avis donné pour tous les cas" />
                : content = <ExclamationCircleFilled className="status-icon icon-exclamation" title="il vous reste des cas à traiter"/>
            return content;
        } },
        { title: '', dataIndex: '', key: 'action', render: (text, record) => (
            <Button className={"invisibleButton"} onClick={(event) => {
                    event.stopPropagation();
                    openStaffDetail(record)}}>
                <InfoCircleOutlined />
            </Button>
        ) },
    ];
    if( staffStore.fetchedStaffList.length === 0 ) {
        content = (
            <Typography className="page-content-placeholder">
                Vous ne participez à aucun staff pour le moment.
            </Typography>
        )
    } else {
        content = (
            <div id="staffList">
                <Table columns={columns} 
                    dataSource={tableData}
                    expandable={{
                        expandedRowRender: record => {
                            setSubjectStaffId(record.id);
                            return(

                                <CaseTable meetingId={record.id} caseList={record.caseList} />
                            )
                        },
                        expandRowByClick: true,
                        expandIcon: () => <></>
                    }}
                />
            </div>
        )
    }

    let modify;

    if(staffDetailModalContent && authStore.getUser._id === staffDetailModalContent.creator && !isEditMode){
        modify = (<Row justify="end">
            <Col >
                <Popconfirm
                    className="delete-popconfirm"
                    title="Êtes-vous sûr de vouloir supprimer ce staff?"
                    onConfirm={handleDeleteStaff}
                    onCancel={(e)=>console.log(e)}
                    okText="Oui"
                    cancelText="Non"
                    cancelButtonProps={cancelButtonProps}
                    okButtonProps={okButtonProps}
                >
                    <Button
                        type="danger"
                        className="delete-button">
                        Supprimer
                    </Button>
                </Popconfirm>
                <Button type="primary" className="neo-primary-btn" onClick={() => setEditMode(!isEditMode)}>
                    Modifier
                </Button>
            </Col>

        </Row>)
    }

    return (
        <>
            {content}
            <Modal 
                className="neo-common-modal" 
                title="Informations Staff" 
                visible={showStaffDetailModal} 
                onCancel={handleCancel}
                footer={null}
                >
                <StaffDetail staff={staffDetailModalContent} isEditProp={isEditMode} />
                { modify }
            </Modal>
        </>
    )
})
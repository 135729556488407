import React, { useState } from 'react';
import { view } from '@risingstack/react-easy-state';
import { Modal, Comment, Typography, Form, Radio, Col, Slider, Row, Button } from 'antd';
import caseStore from './caseStore.js';
import moment from 'moment';

export default view(()=> {
    const [isAverageView, setIsAverageView] = useState(true)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [commentList, setCommentList] = useState([]);

    const showModal = () => {
      setIsModalVisible(true);
    };
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    const toggleAverageView = (event) => {
        setIsAverageView(event.target.value)
    };

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    const handleCommentModal = (commentList) => {
        const commentModalContent = commentList.map( item => {
            return (
                <Comment
                    author={`${item.commenter.firstName} ${item.commenter.lastName  } - ${item.commenter.speciality}`}
                    content={item.comment}
                    datetime={moment(item.createdAt).format("HH:mm DD/MM/YYYY")}
                />
            )
        })
        setCommentList(commentModalContent)
        showModal()
    }

    const DiagnosisList = caseStore.subjectCase.diagnosisList.map( item => {
        const sumOfVotes = item.vote.map( el => el.possibility ).reduce( (accumulator, currentVote) => accumulator + currentVote )
        const averageOfVotes = (sumOfVotes / item.vote.length)
        const marks = {}
        item.vote.forEach( elm => {
            if(marks[`${elm.possibility}`]) {
                marks[`${elm.possibility}`] += 1  
            } else {
                marks[`${elm.possibility}`] = 1
            }
        })

        const hasComment = item.comments.length > 0
        return (
            <>
                <Typography.Title className="possibility-label">
                    {item.probableDiagnosis}
                </Typography.Title>
                <span className="vote-slider-wrapper">
                    <span className="left-label">pas sûr du tout</span>
                        <Form.Item>
                            <Slider 
                                className = "vote-presentation-slider"
                                marks={ !isAverageView ? marks : {} }
                                disabled included={isAverageView} 
                                value={ isAverageView ? averageOfVotes : null } 
                                min={0} max={100} step={1} tooltipVisible={false} 
                            />
                        </Form.Item>

                    <span className="right-label">très sûr</span>
                </span>
                <div className="comment-button-wrapper">
                    <Button
                        className="invisibleButton commentButton"
                        disabled={!hasComment}
                        onClick= {() => handleCommentModal(item.comments)}
                        >
                        Commentaires
                    </Button>
                </div>
            </>
        )
    })

    return(
        <>
            <Typography.Title className="vote-block-title">QUESTION DU MÉDECIN EN CHARGE DU CAS</Typography.Title>
            <Typography.Title className="vote-question-title">Selon vous, quelle est le diagnostic le plus adapté ?</Typography.Title>
            <Row>
                <Col span={16}>
                    <Typography.Title className="vote-question-title">Propositions</Typography.Title>
                    {DiagnosisList}
                </Col>
                <Col span={8}>
                    <div id="vote-mode-wrapper">
                        <Typography.Title className="vote-presentation-mode-title">Afﬁchage des résultats</Typography.Title>
                        <Radio.Group onChange={toggleAverageView} value={isAverageView}>
                            <Radio style={radioStyle} value={true}>
                                Moyenne
                            </Radio>
                            <Radio style={radioStyle} value={false}>
                                Avis individuels
                            </Radio>
                        </Radio.Group>
                    </div>
                </Col>
            </Row>

            <Modal 
                className="neo-common-modal" 
                title="Commentaires" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={null}
                >
                {commentList}
            </Modal>
        </>
    )
})
import { view } from '@risingstack/react-easy-state';
import { Col, Row, Form, Input, Button, Select, Typography } from 'antd';
import { useHistory } from "react-router-dom";

import authStore from './authStore.js';

export default view(()=> {
    const history = useHistory();

    const strongPasswordValidation = async (rule, value) => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        if (!strongRegex.test(value)) {
            throw new Error("Le mot de passe doit contenir au moins 1 minuscule, 1 majuscules, 1 caractère numérique et 8 caractères!")
        }
    }

    const onFinish = (values) => {
        authStore.changePassword(values)
        .then(
            ()=> {
              if(authStore.getUser) {
                history.push('/home')
              }
            }
        );
        // console.log(values)
    };

    return (
        <>
        <Row justify="center">
            <Col span={12} style={{"text-align": "end"}}>
                <Form
                    onFinish={onFinish}
                >
                    {/* <Form.Item
                        name="oldPassword"
                        rules={[
                        {
                            required: true,
                            message: 'Mot de passe!',
                        },
                        ]}
                    >
                        <Input type="password" placeholder="Votre ancien mot de passe" />
                    </Form.Item> */}

                    <Form.Item
                        name="newPassword"
                        rules={[
                        {
                            required: true,
                            message: 'Veuillez saisir votre nouveau mot de passe!',
                        },
                        { validator: strongPasswordValidation }
                        ]}
                        hasFeedback
                    >
                        <Input type="password" placeholder="Veuillez saisir votre nouveau mot de passe" />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['newPassword']}
                        hasFeedback
                        rules={[
                        {
                            required: true,
                            message: 'Veuillez confirmer votre mot de passe!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                            if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('Les deux mots de passe saisis ne correspondent pas!'));
                            },
                        }),
                        ]}
                    >
                        <Input type="password" placeholder="Veuillez confirmer votre mot de passe" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" 
                            className="login-form-button login-form-button-filled">
                                Valider
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
        </>
    )
})

import React, { useEffect } from 'react'

import { Redirect } from "react-router";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { view } from '@risingstack/react-easy-state';
import { Layout } from 'antd';

import authStore from './features/authentication/authStore.js';
import Profile from './features/authentication/Profile.jsx';
import Signin from './features/authentication/Signin.jsx';

import Navbar from './features/shared/Navbar.jsx';
import CaseDetail from './features/case/CaseDetail.jsx';

import ProtectedRoute from './features/shared/ProtectedRoute.jsx';
import Unknown404 from './features/shared/Unknown404.jsx';

import Home from './features/staff/Home.jsx';
import { useHistory } from "react-router-dom";

import './assets/style/App.scss';

const { Header, Content } = Layout;

export default view(() => {


  let sidebar;
  let headerClass = "header";

  if ( authStore.getUser ) {
    headerClass += " userHeader"
  } else {
    headerClass += " publicHeader"
  }
    return (
      <>
        <Router >
          <Layout>
            <Header className={headerClass} id="layout-header">
              <Navbar />
            </Header>
            <Layout>
              {sidebar}
              <Layout>
                <Content
                style={{
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}>
                  <Switch>
                    <ProtectedRoute exact path="/home" type="user" component={Home} />
                    <ProtectedRoute exact path="/profile" type="user" component={Profile} />
                    <ProtectedRoute path="/staff/:meeting_id/case/:case_id" type="user" component={CaseDetail} />

                    <ProtectedRoute exact path="/" type="public" component={Signin} />
                    <Redirect to="/404" component={Unknown404}/>
                  </Switch>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </Router>
      </>
    );
})

import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { view } from '@risingstack/react-easy-state';
import { useParams } from "react-router-dom";
import CaseForm from './CaseForm.jsx'
import caseStore from './caseStore.js';
import authStore from '../authentication/authStore.js';

export default view(()=> {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formDefaults, setFormDefaults] = useState(false);

    const {meeting_id, case_id} = useParams();
    const editFormInitials = () => {
        const current_user_id = authStore.getUser._id;
        const rawCase = caseStore.subjectCase;
        const newDiagnoseList = rawCase.diagnosisList.map( item => {
            const userVote = item.vote.filter( item => item.voter === current_user_id)[0]
            if(userVote){
                return {probableDiagnosis:item.probableDiagnosis, diagnose_id: item._id, possibility:userVote.possibility, vote_id:userVote._id }
            }
        })
        const processedCase = JSON.parse(JSON.stringify(caseStore.subjectCase))
        processedCase['diagnosisList'] = newDiagnoseList;
        setFormDefaults(processedCase)
    }

    useEffect(()=>{
        editFormInitials();
    }, [setFormDefaults])

    const handleEditCaseSubmission = (values) => {
        caseStore.editCase(values, meeting_id, case_id)
        .then(() => {
            editFormInitials();
            handleCancel()
        })
    }
    
    const showModal = () => {
        setIsModalVisible(true);
        editFormInitials()
    };
    
    const handleCancel = () => {
        setIsModalVisible(false);
        editFormInitials()
    };

    return(
        <>
            <div id="modify-case-wrapper">
                <Button onClick={showModal} type="primary" id="modify-case-button">
                    Modiﬁer
                </Button>
            </div>
            <Modal 
                className="neo-common-modal" 
                title="Informations proﬁl" 
                visible={isModalVisible} 
                onCancel={handleCancel}
                footer={null}
                >
                <CaseForm handleSubmission={handleEditCaseSubmission} defaults={formDefaults} />
            </Modal>
        </>
    )
})